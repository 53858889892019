import React from "react";
import { Link } from "gatsby";

const TopHeader = () => {
  return (
    <div id="home" className="top-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-8">
            <div className="address-bar">
              <ul className="list-inline">
                <li>
                  <a href="mailto:info@dhamira.com">
                    <i className="fa fa-envelope"></i> info@dhamira.com
                  </a>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-volume-control-phone"></i>{" "}
                    +1-805-307-8682
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-5 col-md-4">
            <div className="social-icons">
              <ul className="list-inline">
                <li>
                  <a
                    href="https://www.facebook.com/dhamirainc"
                    target="_blank"
                    aria-label="Facebook"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/DhamiraInc"
                    target="_blank"
                    aria-label="Twitter"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/dhamira"
                    target="_blank"
                    aria-label="LinkedIn"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
